import React from 'react';
import './LeftSidebar.css';


export default function LeftSidebar() {

  return (
    <div className='fixed'>
    </div>
  )
}
